import React, { useEffect, useRef } from "react";
import { styled } from "@mui/material";
import { GoogleMap } from "types/map";
import useMapRenderer from "hooks/maps/useMapRenderer";

const MapBoundaries = styled("div")`
  height: 100vh;
`;

/*
 * SDK MapOptions documentation:
 *
 * https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
 */
interface Props extends google.maps.MapOptions {
  map: google.maps.Map | null;
  onMapInitialized: (googleMap: GoogleMap) => void;
  onClick?: (event: google.maps.MapMouseEvent) => void;
  onIdle?: (map: GoogleMap) => void;
  children: React.ReactElement;
}

const MapContainer = ({
  children,
  map,
  onClick,
  onIdle,
  onMapInitialized,
  ...googleMapOptions
}: Props) => {
  const wrapperReference = useRef<HTMLDivElement | null>(null);

  useMapRenderer({
    onMapInitialized,
    mapWrapperReference: wrapperReference,
    googleMapOptions,
  });

  // Register det events "click" and "idle"
  // Full list: https://developers.google.com/maps/documentation/javascript/events
  useEffect(() => {
    map && google.maps.event.clearListeners(map, "click");
    map && google.maps.event.clearListeners(map, "idle");
    map && onClick && map.addListener("click", onClick);
    map && onIdle && map.addListener("idle", () => onIdle(map));
  }, [map, onClick, onIdle]);

  return (
    <>
      <MapBoundaries ref={wrapperReference} />
      {children}
    </>
  );
};

export default MapContainer;
