import { useMemo, SyntheticEvent, useCallback } from "react";
import { tap, uniqBy, trim, orderBy, map, concat, flow } from "lodash/fp";
import useGenericCars from "hooks/cars/useGenericCars";
import { useSelector } from "react-redux";
import { CarGenericState, CarState } from "types/cars";
import { selectMyCars } from "redux/slices/shed/shedSlice";
import { useAppDispatch } from "redux/hooks";
import { registerReferenceCar } from "redux/slices/shed/shedSlice";
import { genericCarToCar, carToCar } from "libraries/car";

type Props = {
  inputValue: string;
  onSearchCar: (licensePlate: string) => void;
};

type ReturnType = {
  carOptions: CarStateOption[];
  handleSelection: (
    event: SyntheticEvent,
    selectedCar: string | CarStateOption | null
  ) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleAddSearchOption: (
    options: CarStateOption[],
    inputValue: string
  ) => CarStateOption[];
};

export type CarStateOption = Partial<CarState> & {
  indexKey: number;
  genericVin?: string;
  inputValue?: string;
};

const mapNoCap = (map as any).convert({ cap: false });

const useCarSelectReference = ({
  onSearchCar,
  inputValue,
}: Props): ReturnType => {
  const dispatch = useAppDispatch();
  const myCars = useSelector(selectMyCars);
  const { getLocalCars } = useGenericCars();

  const carOptions: CarStateOption[] = useMemo(
    () =>
      flow(
        concat(myCars),
        uniqBy(
          ({ carBrand, carModel }: CarStateOption) => `${carBrand}-${carModel}`
        ),
        mapNoCap(
          (carOption: CarStateOption, index: number): CarStateOption => ({
            ...carOption,
            indexKey: index,
            carBrand: carOption.carBrand,
            emissionCO2: carOption.emissionCO2,
            carModel: carOption.carModel,
            genericVin: carOption.genericVin ?? "",
            licensePlate: carOption?.licensePlate ?? "",
          })
        ),
        orderBy(["licensePlate", "carBrand"], ["desc", "asc"]),
        tap(console.log)
      )(getLocalCars()),
    [myCars, getLocalCars]
  );

  const handleSelection = useCallback(
    (event: SyntheticEvent, selectedCar: string | CarStateOption | null) => {
      if (!selectedCar || typeof selectedCar !== "object") {
        return; // No car case
      }

      // New Car case
      selectedCar.inputValue && onSearchCar(selectedCar.inputValue);

      // Existing car case
      selectedCar.licensePlate &&
        dispatch(registerReferenceCar(carToCar(selectedCar as CarState)));

      // Generic car case
      selectedCar.genericVin &&
        dispatch(
          registerReferenceCar(genericCarToCar(selectedCar as CarGenericState))
        );
    },
    [dispatch, onSearchCar]
  );

  const handleAddSearchOption = useCallback(
    (options: CarStateOption[], inputValue: string) => {
      const isIdentifierFound = options.some(
        (option) =>
          inputValue === option.licensePlate || inputValue === option.genericVin
      );

      inputValue &&
        !isIdentifierFound &&
        options.push({
          indexKey: -1,
          inputValue,
          genericVin: "",
          carBrand: "",
          emissionCO2: 0,
          carModel: "",
          licensePlate: "",
        });
      return options;
    },
    []
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const inputValue = trim(event.target.value);
      const hasCar = carOptions.some(
        (car: CarStateOption) => car.licensePlate === inputValue
      );

      inputValue && !hasCar && onSearchCar(event.target.value);
    },
    [carOptions, onSearchCar]
  );

  return {
    carOptions,
    handleSelection,
    handleAddSearchOption,
    handleBlur,
  };
};

export default useCarSelectReference;
