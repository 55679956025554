import { Box, Typography, Slider, styled, Paper, Stack } from "@mui/material";
import Co2Icon from "@mui/icons-material/Co2";
import ParkIcon from "@mui/icons-material/Park";
import AdjustIcon from "@mui/icons-material/Adjust";
import MapImpactPanelRow from "components/map/MapImpactPanelRow";
import useMapImpactSlider from "hooks/maps/useMapImpactSlider";

const PaperPanel = styled(Paper)(({ theme }) => ({
  bottom: theme.spacing(3),
  right: theme.spacing(3),
  borderRadius: 16,
  position: "fixed",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  overflow: "visible",
}));

const currencyNOK = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "NOK",
});

const MapEmbedPanelContainer = () => {
  const {
    savedTrees,
    savedArea,
    savedCO2,
    sliderValue,
    sliderMaxValue,
    onSliderChange,
    logSliderInfo,
  } = useMapImpactSlider();

  return (
    <PaperPanel elevation={3}>
      <Stack width="100%" gap={1}>
        <Slider
          valueLabelDisplay="auto"
          min={0}
          step={1000}
          max={sliderMaxValue}
          valueLabelFormat={(x) => currencyNOK.format(x)}
          value={sliderValue}
          onChange={(_, value) => onSliderChange(value as number)}
          onChangeCommitted={(_, value) => logSliderInfo(value as number)}
          color="secondary"
        />

        <Box>
          <MapImpactPanelRow
            label="Number of trees saved"
            metric={savedTrees}
            icon={<ParkIcon color="success" />}
          />
          <MapImpactPanelRow
            label="Saved area impact (m²)"
            metric={savedArea}
            icon={<AdjustIcon color="success" />}
          />
          <MapImpactPanelRow
            label="Saved CO2 impact (tons)"
            metric={savedCO2}
            icon={<Co2Icon color="success" />}
          />
        </Box>
        <Typography textAlign="end" variant="caption" color="text.disabled">
          Powered by Shed.no
        </Typography>
      </Stack>
    </PaperPanel>
  );
};

export default MapEmbedPanelContainer;
