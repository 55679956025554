import { Typography, Chip } from "@mui/material";
import ParkIcon from "@mui/icons-material/Park";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import LaptopIcon from "@mui/icons-material/Laptop";
import { metersToKm } from "libraries/emission";
import { useAppSelector } from "redux/hooks";
import {
  selectReferenceTrip,
  selectTripTravelMode,
} from "redux/slices/shed/shedSlice";

type Props = {
  tripTreesCount: number;
  distanceMeters: number;
  totalKgEmission: number;
};

const TripEmissionDetails = ({
  tripTreesCount,
  distanceMeters,
  totalKgEmission,
}: Props) => {
  const referenceTrip = useAppSelector(selectReferenceTrip);
  const tripTravelMode = useAppSelector(selectTripTravelMode);

  const treeCount = tripTreesCount.toFixed(1);

  const tripPlural = (count: number) => (count === 1 ? "trip" : "trips");

  const tripKM = `${Math.round(
    metersToKm(distanceMeters * referenceTrip.commuteFrequency)
  )} KM`;
  const tripCO2 = `${(totalKgEmission * referenceTrip.commuteFrequency).toFixed(
    1
  )} KG CO2`;

  return (
    <Typography variant="body2" component="div">
      {/* Conditional rendering based on tripTravelMode */}
      {tripTravelMode === "DRIVING" ? (
        <>
          <Chip
            size="small"
            sx={{ mx: 0.25, alignItems: "flex-end" }}
            icon={
              <DirectionsCarIcon
                fontSize="small"
                sx={{ alignSelf: "center" }}
              />
            }
            label={referenceTrip.commuteFrequency}
          />
          {tripPlural(referenceTrip.commuteFrequency)} ({tripKM}) emit {tripCO2}
          , being the same as what
          <Chip
            size="small"
            sx={{ mx: 0.25, alignItems: "flex-end" }}
            icon={<ParkIcon fontSize="small" sx={{ alignSelf: "center" }} />}
            label={treeCount}
          />
          rainforest trees absorb yearly.
        </>
      ) : tripTravelMode === "WALKING" ? (
        <>
          <Chip
            size="small"
            sx={{ mx: 0.25, alignItems: "flex-end" }}
            icon={
              <DirectionsWalkIcon
                fontSize="small"
                sx={{ alignSelf: "center" }}
              />
            }
            label={referenceTrip.commuteFrequency}
          />
          {tripPlural(referenceTrip.commuteFrequency)} ({tripKM}) saves the
          environment for {tripCO2} compared to using a car. By walking you save
          CO2 equivalent to what {treeCount} rainforest trees absorb yearly.
        </>
      ) : tripTravelMode === "BICYCLING" ? (
        <>
          <Chip
            size="small"
            sx={{ mx: 0.25, alignItems: "flex-end" }}
            icon={
              <DirectionsBikeIcon
                fontSize="small"
                sx={{ alignSelf: "center" }}
              />
            }
            label={referenceTrip.commuteFrequency}
          />
          {tripPlural(referenceTrip.commuteFrequency)} ({tripKM}) saves the
          environment for {tripCO2} compared to using your own car. By biking
          you save CO2 equivalent to what {treeCount} rainforest trees absorb
          yearly.
        </>
      ) : tripTravelMode === "TRANSIT" ? (
        <>
          <Chip
            size="small"
            sx={{ mx: 0.25, alignItems: "flex-end" }}
            icon={
              <DirectionsBusIcon
                fontSize="small"
                sx={{ alignSelf: "center" }}
              />
            }
            label={referenceTrip.commuteFrequency}
          />
          {tripPlural(referenceTrip.commuteFrequency)} ({tripKM}) saves the
          environment for {tripCO2} compared to using a car. By using transit,
          you save CO2 equivalent to what {treeCount} rainforest trees absorb
          yearly.
        </>
      ) : tripTravelMode === "DIGITAL" ? (
        <>
          <Chip
            size="small"
            sx={{ mx: 0.25, alignItems: "flex-end" }}
            icon={<LaptopIcon fontSize="small" sx={{ alignSelf: "center" }} />}
            label={referenceTrip.commuteFrequency}
          />
          digital meetings allow you to travel ({tripKM}) less and saves the
          environment for {tripCO2} compared to using a car. By having a digital
          meeting you save CO2 equivalent to what {treeCount} rainforest trees
          absorb yearly.
        </>
      ) : null}
    </Typography>
  );
};

export default TripEmissionDetails;
