import { useEffect, useRef } from "react";
import { MapExperienceMarker } from "types/map";
import MapMarkerExperience from "components/map/MapMarkerExperience";
import { useAppSelector } from "redux/hooks";
import { selectReferenceExperience } from "redux/slices/shed/shedSlice";

type AdvancedMarkerElementProps = {
  map: google.maps.Map | null; // Map instance passed from MapContainer
};

const NO_MARKERS: MapExperienceMarker[] = [];

const MapMarkersExperience = ({ map }: AdvancedMarkerElementProps) => {
  const boundsRef = useRef<google.maps.LatLngBounds | null>(null);

  const mapExperience = useAppSelector(selectReferenceExperience);
  const markers = mapExperience?.markers ?? NO_MARKERS;
  const experienceId = mapExperience?.experienceId ?? "";

  useEffect(() => {
    if (map && markers.length > 1) {
      boundsRef.current = !boundsRef.current
        ? new google.maps.LatLngBounds()
        : boundsRef.current;

      // Extend the bounds to include each marker's position
      markers.forEach((marker) => {
        boundsRef.current?.extend(
          new google.maps.LatLng(marker.lat, marker.lng)
        );
      });

      // Fit the map to the calculated bounds
      map.fitBounds(boundsRef.current);
    } else if (map && markers.length === 1) {
      map.setCenter({ lat: markers[0].lat, lng: markers[0].lng });
      map.setZoom(14);
    }
  }, [map, markers]);

  return (
    <>
      {markers.map((experience: MapExperienceMarker, index: number) => (
        <MapMarkerExperience
          key={experienceId + index}
          map={map}
          marker={experience}
        />
      ))}
    </>
  );
};

export default MapMarkersExperience;
