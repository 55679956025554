export type GoogleDirectionResult = google.maps.DirectionsResult;
export type GoogleMap = google.maps.Map;

export enum TravelMode {
  DRIVING = "DRIVING",
  BICYCLING = "BICYCLING",
  TRANSIT = "TRANSIT",
  WALKING = "WALKING",
  DIGITAL = "DIGITAL",
}

export type MapLocation = {
  formattedAddress: string | undefined;
  latitude: number | null;
  longitude: number | null;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export type MapItinerary = {
  locationFrom: MapLocation;
  locationTo: MapLocation;
  distanceMeters: number;
  distanceLabel: string;
  durationSeconds: number;
  googleDirection?: GoogleDirectionResult | null;
  bounds: google.maps.LatLngBounds | null;
};

export type EXPERIENCE_ID =
  | "shed-us-kentucky-bourbon"
  | "shed-norway-rainforest-foundation"
  | "shed-norway-help-partner"
  | "shed-norway-fortum-partner"
  | "help-norway-court-house"
  | "vif-norway-football-club";

export type MapExperienceMarker = {
  experienceId: EXPERIENCE_ID;
  lat: number;
  lng: number;
  circleRadius: number;
  name: string;
  cardId: string;
};

export type MapExperience = {
  experienceId: EXPERIENCE_ID;
  valueImpact: number;
  markers: Array<MapExperienceMarker>;
};
