import { useRef } from "react";
import {
  Paper,
  styled,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import useAppNavigate from "hooks/useAppNavigate";
import useIsAuthenticated from "hooks/useIsAuthenticated";
import { ROUTE } from "components/base-app/AppRoutes";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CommuteIcon from "@mui/icons-material/Commute";
import MenuIcon from "@mui/icons-material/Menu";
import MapIcon from "@mui/icons-material/Map";
import { useBoolean } from "hooks/core/useBoolean";
import { useAppSelector } from "redux/hooks";
import { selectReferenceExperienceId } from "redux/slices/shed/shedSlice";

const ApplicationMenuPanel = styled(Paper)(({ theme }) => ({
  top: 0,
  right: 0,
  borderRadius: "50%",
  position: "fixed",
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(2),
}));

const MapApplicationMenu = () => {
  const navigateTo = useAppNavigate();
  const isAuthenticated = useIsAuthenticated();
  const experienceId = useAppSelector(selectReferenceExperienceId);

  const menuElement = useRef(null);
  const {
    value: isOpen,
    setTrue: openMenu,
    setFalse: closeMenu,
  } = useBoolean(false);

  const mapRoute = `${ROUTE.MAP}?experienceId=${experienceId}`;

  return (
    <>
      <ApplicationMenuPanel elevation={3}>
        <IconButton ref={menuElement} onClick={openMenu}>
          <MenuIcon />
        </IconButton>
      </ApplicationMenuPanel>
      <Menu anchorEl={menuElement.current} open={isOpen} onClose={closeMenu}>
        {isAuthenticated && (
          <MenuItem onClick={() => navigateTo(mapRoute)}>
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText>Map</ListItemText>
          </MenuItem>
        )}
        {isAuthenticated && (
          <MenuItem onClick={() => navigateTo(ROUTE.TRIPS_DASHBOARD)}>
            <ListItemIcon>
              <CommuteIcon />
            </ListItemIcon>
            <ListItemText>My trips</ListItemText>
          </MenuItem>
        )}
        {isAuthenticated && (
          <MenuItem onClick={() => navigateTo(ROUTE.PROFILE_PAGE)}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText>My profile</ListItemText>
          </MenuItem>
        )}
        {!isAuthenticated && (
          <MenuItem onClick={() => navigateTo(ROUTE.LOG_IN_EMAIL_AND_PASSWORD)}>
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText>Login</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default MapApplicationMenu;
