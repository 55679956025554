import { useCallback, useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import { MapExperienceMarker } from "types/map";
import { registerReferenceMarker } from "redux/slices/shed/shedSlice";
import { useAppDispatch } from "redux/hooks";
import { getMarkerCardImageSrc } from "libraries/map";

type AdvancedMarkerElementProps = {
  map: google.maps.Map | null; // Map instance passed from MapContainer
  marker: MapExperienceMarker;
};

const MapMarkerExperience = ({ map, marker }: AdvancedMarkerElementProps) => {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null
  );
  const circleRef = useRef<google.maps.Circle | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const handleExperienceMarkerClick = useCallback(() => {
    console.log("[Map] Experience marker click", marker.name);
    dispatch(registerReferenceMarker(marker));

    // Consider adding "registerReferenceExperience"
  }, [dispatch, marker]);

  useEffect(() => {
    if (!map) return;

    // Create the HTML content for the AdvancedMarkerElement
    if (!contentRef.current) {
      contentRef.current = document.createElement("div");
      contentRef.current.innerHTML = `<img style="box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);" src="${getMarkerCardImageSrc(
        marker
      )}" alt="${marker.name}" />`;
    }

    // Create marker only once
    if (!markerRef.current) {
      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        map,
        position: {
          lat: marker.lat,
          lng: marker.lng,
        },
        content: contentRef.current,
        title: marker.name,
      });

      markerRef.current.addListener("click", handleExperienceMarkerClick);
    }

    if (!circleRef.current && marker.circleRadius > 0) {
      circleRef.current = new google.maps.Circle({
        map,
        radius: marker.circleRadius,
        strokeWeight: 2,
        strokeColor: theme.palette.success.main,
        fillColor: theme.palette.success.main,
        fillOpacity: 0.2,
        center: {
          lat: marker.lat,
          lng: marker.lng,
        },
      });
    }

    return () => {
      // Cleanup: Remove content and marker from the map
      if (markerRef.current) {
        markerRef.current.map = null;
        markerRef.current = null;
      }
      if (circleRef.current) {
        circleRef.current = null;
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [map]);

  return null;
};

export default MapMarkerExperience;
