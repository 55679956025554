import { useCallback } from "react";
import { Button, Box } from "@mui/material";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import { registerReferenceTripTravelMode } from "redux/slices/shed/shedSlice";
import { selectTripTravelMode } from "redux/slices/shed/shedSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { TravelMode } from "types/map";
import LaptopIcon from "@mui/icons-material/Laptop";

const MapSelectTravelMode = () => {
  const travelMode = useAppSelector(selectTripTravelMode);

  const dispatch = useAppDispatch();

  const changeToDriveMode = useCallback(
    () => dispatch(registerReferenceTripTravelMode(TravelMode.DRIVING)),
    [dispatch]
  );

  const changeToBikeMode = useCallback(
    () => dispatch(registerReferenceTripTravelMode(TravelMode.BICYCLING)),
    [dispatch]
  );

  const changeToWalkMode = useCallback(
    () => dispatch(registerReferenceTripTravelMode(TravelMode.WALKING)),
    [dispatch]
  );

  const changeToTransitMode = useCallback(
    () => dispatch(registerReferenceTripTravelMode(TravelMode.TRANSIT)),
    [dispatch]
  );

  const changeToDigital = useCallback(
    () => dispatch(registerReferenceTripTravelMode(TravelMode.DIGITAL)),
    [dispatch]
  );

  return (
    <Box display="flex" justifyContent="space-between">
      <Button
        sx={{
          flexDirection: "column", // Stack the icon and text vertically
        }}
        color={travelMode === TravelMode.DRIVING ? "secondary" : undefined}
        onClick={changeToDriveMode}
        startIcon={<DirectionsCarIcon />}
      >
        DRIVE
      </Button>
      <Button
        sx={{
          flexDirection: "column", // Stack the icon and text vertically
        }}
        color={travelMode === TravelMode.WALKING ? "secondary" : undefined}
        onClick={changeToWalkMode}
        startIcon={<DirectionsWalkIcon />}
      >
        Walk
      </Button>
      <Button
        sx={{
          flexDirection: "column", // Stack the icon and text vertically
        }}
        color={travelMode === TravelMode.BICYCLING ? "secondary" : undefined}
        onClick={changeToBikeMode}
        startIcon={<DirectionsBikeIcon />}
      >
        Bike
      </Button>
      <Button
        sx={{
          flexDirection: "column", // Stack the icon and text vertically
        }}
        color={travelMode === TravelMode.TRANSIT ? "secondary" : undefined}
        onClick={changeToTransitMode}
        startIcon={<DirectionsBusIcon />}
      >
        Transit
      </Button>
      <Button
        sx={{
          flexDirection: "column", // Stack the icon and text vertically
        }}
        color={travelMode === TravelMode.DIGITAL ? "secondary" : undefined}
        onClick={changeToDigital}
        startIcon={<LaptopIcon />}
      >
        Digital
      </Button>
    </Box>
  );
};

export default MapSelectTravelMode;
