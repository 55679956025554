import { useCallback, useState } from "react";
import { Collapse, styled, Paper, Stack } from "@mui/material";
import MapLocationField from "components/map/MapLocationField";
import { MapLocation } from "types/map";
import TripCommuteFrequency from "components/trips/TripCommuteFrequency";
import useReferenceTripCommuteFrequency from "hooks/trips/useReferenceTripCommuteFrequency";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useCarSearch from "hooks/useCarSearch";
import CarBrandLoader from "components/cars/CarBrandLoader";
import CarSelectReference from "components/cars/CarSelectReference";
import {
  registerReferenceTripLocation,
  clearItinerary,
  clearTrip,
  selectTripTravelMode,
} from "redux/slices/shed/shedSlice";
import MapTripDetails from "components/map/MapTripDetails";
import MapToolbarCollapse from "components/map/MapToolbarCollapse";
//import MapCardReference from "components/map/MapCardReference";
import MapSelectTravelMode from "components/map/MapSelectTravelMode";
//import useIsMobile from "hooks/base-app/useIsMobile";
import { selectReferenceExperience } from "redux/slices/shed/shedSlice";

const PaperPanel = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    height: "100%",
  },
  borderRadius: 0,
  padding: theme.spacing(1),
  overflow: "auto",
}));

type Props = {
  locationFrom: MapLocation;
  locationTo: MapLocation;
};

const MapPanelContainer = ({ locationFrom, locationTo }: Props) => {
  const [selectedValue, setSelectedValue] = useState("");

  const { smoothLoading, handleSearchCar } = useCarSearch();

  const { commuteFrequency, increaseFrequency, decreaseFrequency } =
    useReferenceTripCommuteFrequency();

  const [isMinimized, setIsMinimized] = useState(true);

  const dispatch = useAppDispatch();
  //const isMobile = useIsMobile();

  const tripTravelMode = useAppSelector(selectTripTravelMode);
  const referenceExperience = useAppSelector(selectReferenceExperience);

  const handleLocationFromChange = useCallback(
    (newFromLocation: MapLocation) => {
      console.log(
        "[Map] Change Location From",
        newFromLocation.formattedAddress
      );
      dispatch(
        registerReferenceTripLocation({
          fromLatitude: newFromLocation.latitude ?? 0,
          fromLongitude: newFromLocation.longitude ?? 0,
          fromAddress: newFromLocation.formattedAddress ?? "",
        })
      );
    },
    [dispatch]
  );

  const handleLocationToChange = useCallback(
    (newToLocation: MapLocation) => {
      console.log("[Map] Change Location Top", newToLocation.formattedAddress);
      dispatch(
        registerReferenceTripLocation({
          toLatitude: newToLocation.latitude ?? undefined,
          toLongitude: newToLocation.longitude ?? undefined,
          toAddress: newToLocation.formattedAddress ?? undefined,
        })
      );
    },
    [dispatch]
  );

  const toggleMinimize = useCallback(() => {
    setIsMinimized(!isMinimized);
  }, [isMinimized]);

  const handleClearTrip = useCallback(() => {
    setIsMinimized(false);
    dispatch(clearTrip());
    dispatch(clearItinerary());
  }, [dispatch]);

  return (
    <PaperPanel elevation={3}>
      <img
        style={{ borderRadius: 8, maxWidth: "20%" }}
        src={`${process.env.PUBLIC_URL}/${referenceExperience?.experienceId}.svg`}
        alt={""}
      />
      <MapToolbarCollapse
        onClearTrip={handleClearTrip}
        isMinimized={isMinimized}
        onToggle={toggleMinimize}
      >
        <Stack width="100%" gap={0.5}>
          <MapLocationField
            label="From"
            location={locationFrom}
            onChange={handleLocationFromChange}
          />
          <MapLocationField
            label="To"
            location={locationTo}
            onChange={handleLocationToChange}
          />
          <TripCommuteFrequency
            commuteFrequency={commuteFrequency}
            increaseFrequency={increaseFrequency}
            decreaseFrequency={decreaseFrequency}
          />
          <Collapse in={tripTravelMode === "DRIVING"}>
            <Stack gap={0.5}>
              <CarSelectReference
                size="small"
                onSearchCar={handleSearchCar}
                onInputChange={setSelectedValue}
                inputValue={selectedValue}
              />
              <CarBrandLoader loading={smoothLoading} />
            </Stack>
          </Collapse>
          <MapSelectTravelMode />
        </Stack>
      </MapToolbarCollapse>
      <MapTripDetails />
      {/*!isMobile && <MapCardReference />*/}
    </PaperPanel>
  );
};

export default MapPanelContainer;
