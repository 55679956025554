import { useCallback } from "react";
import { SIGNIN_EMAIL_AND_PASSWORD } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { ROUTE } from "../components/base-app/AppRoutes";
import useAppNavigate from "./useAppNavigate";

type Props = {
  email: string;
  password: string;
  successNavigation: ROUTE | string;
};

type LoginResult = "auth/wrong-password" | "auth/success" | undefined;

type ReturnType = {
  loading: boolean;
  handleLoginEmailAndPassword: () => Promise<LoginResult>;
};

export const useLoginWithEmailAndPassword = ({
  email,
  password,
  successNavigation,
}: Props): ReturnType => {
  const [signInWithEmailAndPassword, { loading }] = useLazyQuery(
    SIGNIN_EMAIL_AND_PASSWORD
  );

  const navigateTo = useAppNavigate();

  const handleLoginEmailAndPassword =
    useCallback(async (): Promise<LoginResult> => {
      try {
        const {
          data: { loginWithEmailAndPassword },
        } = await signInWithEmailAndPassword({
          variables: { email, password },
        });

        if (!loginWithEmailAndPassword) {
          throw new Error("GraphQL signInCreateLink");
        }

        const { token, refreshToken, uid, code } = loginWithEmailAndPassword;

        if (code === "auth/success") {
          localStorage.setItem("login-email", email);
          localStorage.setItem("shed-token", token);
          localStorage.setItem("shed-refresh-token", refreshToken);
          localStorage.setItem("uid", uid);
          navigateTo(successNavigation);
        }

        return code;
      } catch (error) {
        console.error(
          `[Component] LoginMagicLinkPage - Error sending email link`,
          error
        );
      }
    }, [
      email,
      successNavigation,
      password,
      navigateTo,
      signInWithEmailAndPassword,
    ]);

  return {
    loading,
    handleLoginEmailAndPassword,
  };
};
