import { Box, Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { capitalize } from "lodash/fp";
import CarLogoBrand from "./CarLogoBrand";

type Props = {
  carBrand: string;
  emissionCO2: number;
  carModel: string;
  licensePlate: string;
  inputValue: string;
};

const CarSelectRenderOption = ({
  inputValue,
  carBrand,
  emissionCO2,
  carModel,
  licensePlate,
}: Props) => {
  return (
    <Box
      alignItems="flex-end"
      display="flex"
      gap={0.5}
      width="100%"
      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
    >
      {carBrand || carModel || licensePlate ? (
        <>
          <CarLogoBrand size="small" carBrand={carBrand ?? ""} />
          <Typography component="span" noWrap>{capitalize(carBrand ?? "")}</Typography>
          <Typography color="text.secondary" component="span" noWrap>
            {capitalize(carModel ?? "")}
          </Typography>
          {licensePlate && (
            <Typography variant="caption" color="success.main">
              • {licensePlate}
            </Typography>
          )}
          <Box flexGrow={1} />
          {emissionCO2 && (
            <Typography variant="caption" color="success.main">
              {Math.round(emissionCO2)}gCO2/km
            </Typography>
          )}
        </>
      ) : (
        <>
          <SearchIcon />
          <Stack>
            <Typography>Search by license plate</Typography>
            <Typography variant="body1" color="success.main">
              • {inputValue}
            </Typography>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default CarSelectRenderOption;
