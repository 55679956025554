import { Box, Grid, Skeleton, Typography } from "@mui/material";
import CarLogoBrand from "../cars/CarLogoBrand";
import { useAppSelector } from "../../redux/hooks";
import { selectReferenceCar } from "../../redux/slices/shed/shedSlice";

type Props = {
  loading: boolean;
};

const CarBrandLoader = ({ loading }: Props) => {
  const car = useAppSelector(selectReferenceCar);
  return car?.licensePlate || car?.genericVin ? (
    <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
      <Grid item xs={8}>
        <Typography
          variant="body2"
          textTransform="capitalize"
          textAlign="center"
          color="text.secondary"
        >
          {loading ? (
            <Skeleton variant="text" width="50%" sx={{ margin: "auto" }} />
          ) : (
            car.licensePlate
          )}
        </Typography>
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          {loading ? (
            <Skeleton variant="text" width="80%" />
          ) : (
            <>
              <Typography fontWeight={500} textTransform="capitalize">
                {car.carBrand}
              </Typography>
              <Typography ml={0.5} fontWeight={500} textTransform="capitalize">
                {car.carModel}
              </Typography>
            </>
          )}
        </Box>
        <Typography textTransform="capitalize" textAlign="center">
          {loading ? (
            <Skeleton variant="text" width="50%" sx={{ margin: "auto" }} />
          ) : (
            car.emissionType
          )}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="center">
          {loading ? (
            <Skeleton variant="circular" width="45px" height="45px" />
          ) : (
            <CarLogoBrand size="full" carBrand={car.carBrand} />
          )}
        </Box>
      </Grid>
    </Grid>
  ) : null;
};

export default CarBrandLoader;
