import { useEffect, useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { selectReferenceExperience } from "redux/slices/shed/shedSlice";

type ReturnType = {
  savedTrees: number;
  savedArea: number;
  savedCO2: number;
  sliderValue: number;
  sliderMaxValue: number;
  onSliderChange: (newValue: number) => void;
  logSliderInfo: (newValue: number) => void;
};

const SLIDER_MAX_VALUE = 1000000;
const RAINFOREST_TREE_MULTIPLIER = 565; // per Hectar
const RAINFOREST_CURRENCY_MULTIPLIER = 4.56; // per 1 KOR
const RAINFOREST_CO2_MULTIPLIER = 550.5; // per Hectar

const squareMeterToHectar = (squareMeter: number) => squareMeter / 10000;
const getSavedArea = (sliderValue: number) =>
  sliderValue * RAINFOREST_CURRENCY_MULTIPLIER;
const getSavedTrees = (savedArea: number) =>
  squareMeterToHectar(savedArea) * RAINFOREST_TREE_MULTIPLIER;
const getSavedCO2 = (savedArea: number) =>
  squareMeterToHectar(savedArea) * RAINFOREST_CO2_MULTIPLIER;
const getSavedRadius = (savedArea: number) =>
  Math.round(Math.sqrt(savedArea / Math.PI));

const useMapImpactSlider = (): ReturnType => {
  const mapExperience = useAppSelector(selectReferenceExperience);
  const [sliderValue, setSliderValue] = useState(0);
  const [sliderMaxValue, setSliderMaxValue] = useState(SLIDER_MAX_VALUE);

  useEffect(() => {
    const valueImpact = mapExperience?.valueImpact || 0;

    setSliderValue(valueImpact);
    setSliderMaxValue(Math.max(SLIDER_MAX_VALUE, valueImpact));
  }, [mapExperience]);

  const savedArea = getSavedArea(sliderValue);
  const savedTrees = getSavedTrees(savedArea);
  const savedCO2 = getSavedCO2(savedArea);

  const logSliderInfo = useCallback((newValue: number) => {
    const savedArea = getSavedArea(newValue);
    const circleRadius = getSavedRadius(savedArea);

    console.log("[Slider] Saved area impact radius", circleRadius);
  }, []);

  return {
    sliderValue,
    sliderMaxValue,
    onSliderChange: setSliderValue,
    logSliderInfo,
    savedTrees,
    savedArea,
    savedCO2,
  };
};

export default useMapImpactSlider;
