import { lowerCase, flow } from "lodash/fp";

/**
 * SVG for Shed & partners
 */
export const APP_PARTNER_SVG_DEFAULT = process.env.PUBLIC_URL + `/partners/`;
export const APP_PARTNER_SVG_SHED = APP_PARTNER_SVG_DEFAULT + "shed_orange.svg";
export const APP_PARTNER_SVG_RAINFOREST =
  APP_PARTNER_SVG_DEFAULT + "rainforest_black.svg";

/**
 * SVG for Car
 */
export const CAR_BRAND_SVG_BASE = process.env.PUBLIC_URL + `/brands/`;
export const CAR_BRAND_SVG_DEFAULT = CAR_BRAND_SVG_BASE + "car.svg";

export const getCarBrandSvg = (carBrand: string) =>
  process.env.PUBLIC_URL + `/brands/${flow(lowerCase)(carBrand)}.svg`;

/**
 * SVG for Map
 */
export const MAP_MARKERS_SVG_BASE = process.env.PUBLIC_URL + `/markers/`;
export const MAP_MARKERS_SVG_USER = MAP_MARKERS_SVG_BASE + "user.svg";
