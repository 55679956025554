import { Divider, Box, Collapse } from "@mui/material";
import ForestTree from "components/core/ForestTree";
import { useAppSelector } from "redux/hooks";
import {
  selectReferenceItinerary,
  selectReferenceCar,
  selectHasReferenceTrip,
} from "redux/slices/shed/shedSlice";
import { emissionToTrees } from "libraries/emission";
import { styled } from "@mui/material";
import useReferenceTripCommuteFrequency from "hooks/trips/useReferenceTripCommuteFrequency";
import TripEmissionDetails from "components/trips/TripEmissionDetails";
import { tripEmission } from "libraries/emission";

const PANEL_DISPLAY_HEIGHT = 500;

const TripDetailContainerBox = styled(Box)(({ theme }) => ({
  maxHeight: PANEL_DISPLAY_HEIGHT,
  overflow: "hidden",
  scroll: "auto",
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
}));

const MapTripDetails = () => {
  const itinerary = useAppSelector(selectReferenceItinerary);
  const itineraryMeters = itinerary?.distanceMeters ?? 0;
  const referenceCar = useAppSelector(selectReferenceCar);
  const hasTrip = useAppSelector(selectHasReferenceTrip);
  const car = useAppSelector(selectReferenceCar);

  const { commuteFrequency } = useReferenceTripCommuteFrequency();

  const { totalKgEmission } = tripEmission(
    referenceCar,
    itinerary?.distanceMeters ?? 0
  );

  const tripTreesCount = emissionToTrees({
    distanceMeters: itineraryMeters,
    emissionType: car?.emissionType ?? "",
    gramEmission: car?.emissionCO2 ?? 0,
  });

  return (
    <Collapse in={hasTrip}>
      <TripDetailContainerBox display="flex" flexDirection="column" gap={1}>
        <Divider />
        <TripEmissionDetails
          tripTreesCount={tripTreesCount}
          distanceMeters={itinerary?.distanceMeters ?? 0}
          totalKgEmission={totalKgEmission}
        />
        <ForestTree
          treeCount={tripTreesCount * commuteFrequency}
          donated={0}
          leftToDonate={0}
        />
      </TripDetailContainerBox>
    </Collapse>
  );
};
export default MapTripDetails;
