import { useCallback } from "react";
import { LoadingButton } from "@mui/lab";
import DirectionsCar from "@mui/icons-material/DirectionsCar";
import useSaveItineraryToTrip from "hooks/useSaveItineraryToTrip";
import { MapItinerary } from "types/map";
import useIsAuthenticated from "hooks/useIsAuthenticated";
import { noop } from "lodash";
import { useAppSelector } from "redux/hooks";
import useCarSave from "hooks/useCarSave";
import { selectReferenceCar } from "redux/slices/shed/shedSlice";

type Props = {
  itinerary: MapItinerary | null;
};

const SaveTripButton = ({ itinerary }: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const referenceCar = useAppSelector(selectReferenceCar);

  const { loading: loadingTripSave, handleSaveItineraryToTrip } =
    useSaveItineraryToTrip({
      itinerary,
      onSaved: noop,
    });

  const { loading: loadingCarSave, handleSaveCar: handleSaveCarThenTrip } =
    useCarSave({
      carToSave: referenceCar,
      onSaved: handleSaveItineraryToTrip,
    });

  const handleUserTripSave = useCallback(() => {
    referenceCar?.id
      ? handleSaveItineraryToTrip(referenceCar)
      : handleSaveCarThenTrip();
  }, [referenceCar, handleSaveItineraryToTrip, handleSaveCarThenTrip]);

  return isAuthenticated ? (
    <LoadingButton
      color="primary"
      startIcon={<DirectionsCar />}
      loadingPosition="start"
      disabled={!referenceCar || !itinerary}
      loading={loadingTripSave || loadingCarSave}
      onClick={handleUserTripSave  }
    >
      Save
    </LoadingButton>
  ): null ;
};

export default SaveTripButton;
