import { useState } from "react";
import MapContainer from "components/map/MapContainer";
import { GoogleMap } from "types/map";
import { LatLng } from "types/map";
import useMapShareTripParams from "hooks/maps/useMapShareTripParams";
import MapMarkersExperience from "components/map/MapMarkersExperience";
import MapEmbedPanelContainer from "components/map/MapEmbedPanelContainer";

const MapEmbedPage = () => {
  useMapShareTripParams();

  const [map, setMap] = useState<GoogleMap | null>(null);
  const [defaultCenterGeo] = useState<LatLng>({ lat: 0, lng: 0 });
  const [defaultZoomLevel] = useState(3);

  return (
    <MapContainer
      map={map}
      onMapInitialized={setMap}
      center={defaultCenterGeo}
      zoom={defaultZoomLevel}
      disableDefaultUI
      zoomControl={false}
    >
      <>
        <MapMarkersExperience map={map} />
        <MapEmbedPanelContainer />
      </>
    </MapContainer>
  );
};

export default MapEmbedPage;
