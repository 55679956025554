import { ReactNode } from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  label: string;
  metric: number;
  icon: ReactNode;
};

const MapImpactPanelRow = ({ label, metric, icon }: Props) => (
  <Box display="flex" gap={0.5} alignItems="center">
    {icon}
    <Typography variant="body2" color="text.secondary">
      {label}:
    </Typography>
    <Typography minWidth={80} color="success.main" fontWeight={500}>
      {Math.round(metric).toLocaleString(undefined)}
    </Typography>
  </Box>
);

export default MapImpactPanelRow;
