import { useCallback } from "react";
import genericCarsEU from "libraries/genericEU.json";
import { map } from "lodash/fp";
import genericCarsUS from "libraries/genericUS.json";
import { genericCarToCar } from "libraries/car";
import { CarState } from "types/cars";

type ReturnType = {
  getLocalCars: () => CarState[];
};

const DEFAULT_CARS_SET = genericCarsUS;

const useGenericCars = (): ReturnType => {
  const getLocalCars = useCallback(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const isEU =
      userTimezone.startsWith("Europe") ||
      userTimezone.startsWith("Atlantic/Azores") ||
      userTimezone.startsWith("Africa/Casablanca");

    const isUS = userTimezone.startsWith("America");

    console.log(`[GenericCars] Timezone region US [${isUS}] ; EU [${isEU}]`);

    const genericCarsSet = isEU
      ? genericCarsEU
      : isUS
      ? genericCarsUS
      : DEFAULT_CARS_SET;

    return map(genericCarToCar)(genericCarsSet);
  }, []);

  return { getLocalCars };
};

export default useGenericCars;
