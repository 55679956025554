import { useCallback, useState, useEffect, useContext } from "react";
import { MapContext } from "../../providers/MapProvider";
import { MapLocation, MapItinerary } from "types/map";
import {
  selectTripTravelMode,
  registerReferenceItinerary,
} from "redux/slices/shed/shedSlice";
import {
  hasLatLong,
  mapDirectionResultParser,
  toGoogleLatLong,
} from "libraries/map";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { flow } from "lodash/fp";

type DirectionService = google.maps.DirectionsService;

type Props = {
  from: MapLocation;
  to: MapLocation;
};

type ReturnType = MapItinerary | null;

const useDirections = ({ from, to }: Props): ReturnType => {
  const dispatch = useAppDispatch();
  const { initialized, mapsLibraries } = useContext(MapContext);
  const travelMode = useAppSelector(selectTripTravelMode);

  const [directionService, setDirectionService] =
    useState<DirectionService | null>(null);

  const saveDirectionToStore = useCallback(
    (itinerary: MapItinerary) =>
      dispatch(registerReferenceItinerary(itinerary)),
    [dispatch]
  );

  const addLocationToDirection = useCallback(
    (
      parsedDirection: Omit<MapItinerary, "locationFrom" | "locationTo">
    ): MapItinerary => ({
      ...parsedDirection,
      locationFrom: from,
      locationTo: to,
    }),
    [from, to]
  );

  useEffect(() => {
    initialized &&
      mapsLibraries &&
      setDirectionService(new mapsLibraries.DirectionsService());
  }, [initialized, mapsLibraries]);

  useEffect(() => {
    if (directionService && hasLatLong(from) && hasLatLong(to)) {
      directionService.route(
        {
          origin: toGoogleLatLong(from.latitude, from.longitude),
          destination: toGoogleLatLong(to.latitude, to.longitude),
          travelMode:
            travelMode === "DIGITAL" ? "DRIVING" : (travelMode as any),
        },
        flow(
          mapDirectionResultParser,
          addLocationToDirection,
          saveDirectionToStore
        )
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [travelMode, directionService, from, to]);
  return null;
};

export default useDirections;
